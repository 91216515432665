import React, { useEffect, useRef, useState } from 'react';

import { Image } from '@/components/shared/element/image';
import { useWindowSize } from '@/components/shared/utility/hooks';
import { IMAGE_SRCSET_100VW } from '@/const/image';
import { styled } from '@/stitches.config';
import { IImage, IRatio } from '@/types/shared';

const ImageWrap = styled('div', {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  transform: 'translateZ(0)',
  '& video': {
    position: 'absolute',
    left: '-0.5px !important',
    top: 0,
    width: 'calc(100% + 1px) !important',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    transform: 'translateZ(0)',
    maxWidth: 'calc(100% + 1px) !important',
  },
});

interface IProps {
  children?: React.ReactNode;
  css?: any;
  type?: 'image' | 'video';
  image: IImage;
  imageDesktop: IImage;
  video?: string;
  videoDesktop?: string;
  priority?: boolean;
  lazyBoundary?: string;
  onReady?: () => void;
  onVideoEnded?: () => void;
  loading?: 'lazy' | 'eager';
  cropByRatio?: IRatio;
  cropByRatioDesktop?: IRatio;
  preventLoadVideo?: boolean;
}

const View = ({ type = 'image', ...props }: IProps) => {
  const DEFAULT_VIDEO_SRC =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  const [imageLoad, setImageLoad] = useState(false);
  const [videoSrc, setVideoSrc] = useState(DEFAULT_VIDEO_SRC);

  const onImageLoad = () => {
    setImageLoad(true);
  };

  const VIDEO_REF = useRef(null);
  const WINDOW_SIZE = useWindowSize();

  useEffect(() => {
    if (props.preventLoadVideo) return;

    if (type !== 'video') {
      props.onReady?.();
      return;
    }
    if (imageLoad) {
      const isMobile = WINDOW_SIZE.width < 1024;
      setVideoSrc(isMobile ? props.video : props.videoDesktop);
    }
  }, [
    imageLoad,
    props.videoDesktop,
    props.video,
    WINDOW_SIZE.width,
    type,
    props.preventLoadVideo,
  ]);

  const handleVideoEnded = () => {
    if (!VIDEO_REF.current) return;
    if (VIDEO_REF.current.ended) {
      props.onVideoEnded?.();
    }
  };

  const handleLoadedMetadata = () => {
    if (!VIDEO_REF.current) return;
    VIDEO_REF.current.pause();
    props.onReady?.();
  };

  return (
    <ImageWrap>
      <Image
        src={props.image.src}
        alt={props.image.alt}
        srcset={IMAGE_SRCSET_100VW({
          mobileSrc: props.image.src,
          desktopSrc: props.imageDesktop.src,
          cropByRatio: props?.cropByRatio,
          cropByRatioDesktop: props?.cropByRatioDesktop,
        })}
        fullheight
        fade={false}
        blur={false}
        quality={75}
        priority={props.priority}
        onLoad={onImageLoad}
        css={props?.css}
        lazyBoundary={props.lazyBoundary}
        test={{
          dataTest: 'banner_poster',
        }}
      />
      {/* <NextImage
        src={props.image.src}
        srcDesktop={props.imageDesktop.src}
        alt={props.image.alt}
        layout={'fill'}
        cropByRatio={type === 'video' ? '3:4' : '2:3'}
        cropByRatioDesktop={
          props.cropByRatioDesktop ? props.cropByRatioDesktop : '16:9'
        }
        sizes={`(max-width: 639px) and (resolution > 3dppx) ${
          828 / 4 + `px`
        }, (max-width: 639px) and (resolution > 2dppx) ${
          828 / 3 + `px`
        }, (max-width: 639px) and (resolution <= 2dppx) ${
          828 / 2 + `px`
        }, (max-width: 1023px) and (resolution > 3dppx) ${
          1080 / 4 + `px`
        }, (max-width: 1023px) and (resolution > 2dppx) ${
          1080 / 3 + `px`
        }, (max-width: 1023px) and (resolution <= 2dppx) ${
          1080 / 2 + `px`
        }, (min-width: 1024px) and (resolution >= 2dppx) ${
          1920 / 2 + `px`
        }, (min-width: 1920px) and (resolution >= 2dppx) ${
          2560 / 2 + `px`
        }, 1920px`}
        objectFit={'cover'}
        objectPosition={'center center'}
        priority={props.priority}
        lazyBoundary={props.lazyBoundary}
        loading={props.loading}
        onLoad={onImageLoad}
        dataTest="banner_poster"
        css={props?.css}
      /> */}
      {type === 'video' && videoSrc && (
        <video
          ref={VIDEO_REF}
          preload="none"
          autoPlay
          muted
          playsInline
          src={videoSrc}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={handleVideoEnded}
          data-test="video"
        />
      )}
    </ImageWrap>
  );
};

View.displayName = 'SectionMY24SharedMediaItem';
export default View;
