import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';
import React from 'react';

import CustomMetatag from '@/components/layouts/custom-metatag';
import Layout from '@/components/layouts/lcv';
import HeroBanner from '@/components/sections/my24/home/hero-banner';
import {
  CTA_DATA,
  HERO_BANNER_DATA,
  LINEUP_CAROUSEL_DATA,
  META_TAG_DATA,
} from '@/contents/lcv/product/my24/home';

const LineupCarousel = dynamic(
  () => import('@/components/sections/my24/home/lineup-carousel'),
  {
    ssr: false,
  }
);
const CTA = dynamic(() => import('@/components/sections/my24/shared/cta'), {
  ssr: false,
});

export async function getStaticProps() {
  return {
    props: {},
  };
}
export default function Page() {
  return (
    <>
      {(META_TAG_DATA?.title ||
        META_TAG_DATA?.description ||
        META_TAG_DATA?.img?.url) && (
        <CustomMetatag
          title={META_TAG_DATA?.title}
          description={META_TAG_DATA?.description}
          imgURL={META_TAG_DATA?.img?.url}
        />
      )}
      <HeroBanner {...HERO_BANNER_DATA} />
      <LineupCarousel {...LINEUP_CAROUSEL_DATA} />
      <CTA {...CTA_DATA} />
    </>
  );
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
