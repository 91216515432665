import { styled } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

const StyledIconButton = styled('button', {
  all: 'unset',
  us: 'none',
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  textAlign: 'center',
  margin: 0,
  padding: 0,
  cursor: 'pointer',
  br: '50%',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    br: 'inherit',
    pe: 'none',
    transitionDelay: 'var(--transition-delay)',
    transitionDuration: 'var(--transition-duration)',
    transitionTimingFunction: 'var(--transition-easing)',
  },
  '&::before': {
    display: 'block',
    transform: 'scale(0)',
    zIndex: 1,
    bc: '$primary100',
    transitionProperty: 'transform',
  },
  '&::after': {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '$primary',
    bc: '$primary',
    transitionProperty: 'border-color, background-color',
  },
  '& i': {
    position: 'relative',
    zIndex: '1',
    display: 'inline-block',
    pe: 'none',
    color: '$white',
  },
  '@hover': {
    '&.is-hover-button, &:hover': {
      '&::before': {
        transform: 'scale(1)',
      },
    },
  },
  '&.swiper-button-disabled': {
    opacity: '0.2',
    pe: 'none',
  },
  variants: {
    size: {
      '1': {
        size: 36,
        minWidth: 36,
        '& i': {
          size: 22,
          minWidth: 22,
        },
      },
      '2': {
        size: 40,
        minWidth: 40,
        '& i': {
          size: 24,
          minWidth: 24,
        },
      },
      '3': {
        size: 44,
        minWidth: 44,
      },
      '4': {
        size: 48,
        minWidth: 48,
      },
      '5': {
        size: '$6',
        minWidth: '$6',
        '& i': {
          size: 14,
          minWidth: 14,
        },
      },
      '6': {
        size: 36,
        minWidth: 36,
        '& i': {
          size: 18,
          minWidth: 18,
        },
      },
      '7': {
        size: 32,
        minWidth: 32,
        '& i': {
          size: 16,
          minWidth: 16,
        },
      },
      '8': {
        size: 20,
        minWidth: 20,
        '& i': {
          size: 16,
          minWidth: 16,
        },
      },
      '9': {
        size: 56,
        minWidth: 56,
        '& i': {
          size: 24,
          minWidth: 24,
        },
      },
      '10': {
        size: 60,
        minWidth: 60,
      },
      '11': {
        size: 16,
        minWidth: 16,
        '& i': {
          size: 10,
          minWidth: 10,
        },
      },
      '12': {
        size: 56,
        minWidth: 56,
        '& i': {
          size: 28,
        },
      },
    },
    variant: {
      'icon-button-red': {
        '&::before': {
          bc: '$primary100',
        },
        '&::after': {
          bc: '$primary',
          borderColor: '$primary',
        },
      },
      'icon-button-red-hover-white': {
        '&::before': {
          bc: '$white',
        },
        '&::after': {
          bc: '$primary',
        },
        '& i': {
          color: '$white',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$primary',
            },
          },
        },
      },
      'icon-button-blue': {
        '&::before': {
          bc: '$white',
        },
        '&::after': {
          bc: '$blue',
          borderColor: '$blue',
        },
        '& i': {
          color: '$white',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$blue',
            },
          },
        },
      },
      'icon-button-white': {
        '&::before': {
          bc: '$primary',
        },
        '&::after': {
          bc: '$white',
          borderColor: '$white',
        },
        '& i': {
          color: '$primary',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$white',
            },
          },
        },
      },
      'icon-button-white-with-icon-gray': {
        '&::before': {
          bc: '$gray700',
        },
        '&::after': {
          bc: '$white',
          borderColor: '$white',
        },
        '& i': {
          color: '$gray700',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$white',
            },
          },
        },
      },
      'icon-button-gray': {
        '&::before': {
          bc: '$white',
        },
        '&::after': {
          bc: '$gray900',
          borderColor: '$gray900',
        },
        '& i': {
          color: '$white',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$gray900',
            },
          },
        },
      },
      'icon-button-gray-dark': {
        '&::before': {
          bc: '$white',
        },
        '&::after': {
          bc: '$gray800',
          borderColor: '$gray800',
        },
        '& i': {
          color: '$white',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$gray800',
            },
          },
        },
      },
      'icon-button-gray-light': {
        '&::before': {
          bc: '$white',
        },
        '&::after': {
          bc: '$gray700',
          borderColor: '$gray700',
        },
        '& i': {
          color: '$white',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$gray700',
            },
          },
        },
      },
      'icon-button-gray-lightest': {
        '&::before': {
          bc: '$white',
        },
        '&::after': {
          bc: '$gray600',
          borderColor: '$gray600',
        },
        '& i': {
          color: '$white',
        },
        '@hover': {
          '&:hover': {
            '& i': {
              color: '$gray600',
            },
          },
        },
      },
      'icon-button-gray-500': {
        '&::before': {
          bc: '$white',
        },
        '&::after': {
          bc: '$gray500',
          borderColor: '$gray500',
        },
        '& i': {
          color: '$white',
        },
        '@hover': {
          '&:hover': {
            '& i': {
              color: '$gray500',
            },
          },
        },
      },
      'icon-button-gray-dark-overlay': {
        '&::before': {
          bc: '$gray300',
        },
        '&::after': {
          bc: '$gray300',
          borderColor: '$gray300',
        },
        '& i': {
          color: '$gray900',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$white',
            },
            '&::before': {
              bc: '$primary',
            },
            '&::after': {
              bc: '$primary',
              borderColor: '$primary',
            },
          },
        },
      },
      'icon-button-green': {
        '&::before': {
          transform: 'scale(0) !important',
          bc: '$green-lightest',
        },
        '&::after': {
          bc: '$green-lightest',
          borderColor: '$green-lightest',
        },
        '& i': {
          color: '$white',
        },
      },
    },
    border: {
      true: {
        '&::before': {
          transform: 'scale(0) !important',
        },
        '&::after': {
          bc: 'transparent',
        },
        '& i': {
          color: '$primary',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '&::after': {
              bc: '$primary100',
              borderColor: '$primary100',
            },
            '& i': {
              color: '$white',
            },
          },
        },
      },
    },
    shadow: {
      true: {
        bs: '$shadow100',
      },
    },
    nobg: {
      true: {
        '&::after': {
          bc: 'transparent',
          border: 'none',
        },
      },
    },
    hover: {
      true: {},
    },
    nothover: {
      true: {
        opacity: '0.2',
        pe: 'none',
      },
    },
    scrollTopStyle: {
      true: {
        bs: '0px 4px 16px rgba(0, 0, 0, 0.16)',
      },
    },
  },
  compoundVariants: [
    {
      border: 'true',
      variant: 'icon-button-white',
      css: {
        '&::after': {
          bc: 'transparent',
        },
        '& i': {
          color: '$white',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '&::after': {
              bc: '$white',
              borderColor: '$white',
            },
            '& i': {
              color: '$primary',
            },
          },
        },
      },
    },
    {
      border: 'true',
      variant: 'icon-button-white',
      hover: 'true',
      css: {
        '@hover': {
          '&.is-hover-button, &:hover': {
            '&::after': {
              bc: '$white',
              borderColor: '$white',
            },
            '& i': {
              color: '$blue',
            },
          },
        },
      },
    },
    {
      border: 'true',
      variant: 'icon-button-gray',
      css: {
        '&::after': {
          bc: 'transparent',
        },
        '& i': {
          color: '$gray900',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '&::after': {
              bc: '$gray900',
              borderColor: '$gray900',
            },
            '& i': {
              color: '$white',
            },
          },
        },
      },
    },
    {
      border: 'true',
      variant: 'icon-button-gray-light',
      css: {
        '&::after': {
          bc: 'transparent',
        },
        '& i': {
          color: '$gray700',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '&::after': {
              bc: '$gray700',
              borderColor: '$gray700',
            },
            '& i': {
              color: '$white',
            },
          },
        },
      },
    },
    {
      border: 'true',
      variant: 'icon-button-gray-lightest',
      css: {
        '&::after': {
          bc: 'transparent',
        },
        '& i': {
          color: '$gray600',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '&::after': {
              bc: '$gray600',
              borderColor: '$gray600',
            },
            '& i': {
              color: '$white',
            },
          },
        },
      },
    },

    {
      border: 'true',
      variant: 'icon-button-gray-dark',
      css: {
        '&::after': {
          bc: 'transparent',
        },
        '& i': {
          color: '$gray800',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '&::after': {
              bc: '$gray800',
              borderColor: '$gray800',
            },
            '& i': {
              color: '$white',
            },
          },
        },
      },
    },
    {
      nobg: 'true',
      variant: 'icon-button-gray',
      css: {
        '&::before': {
          bc: '$gray900',
        },
        '&::after': {
          bc: 'transparent',
          border: 'none',
        },
        '& i': {
          color: '$gray900',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$white',
            },
          },
        },
      },
    },
    {
      nobg: 'true',
      variant: 'icon-button-white',
      css: {
        '&::before': {
          bc: '$white',
        },
        '&::after': {
          bc: 'transparent',
          border: 'none',
        },
        '& i': {
          color: '$white',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '& i': {
              color: '$gray900',
            },
          },
        },
      },
    },
    {
      border: 'true',
      variant: 'icon-button-white',
      scrollTopStyle: 'true',
      css: {
        '&::before': {
          transform: 'scale(0) !important',
          bc: '$primary',
        },
        '&::after': {
          bc: '$white',
          borderColor: 'rgba(0, 0, 0, 0.02)',
        },
        '& i': {
          color: '$primary',
        },
        '@hover': {
          '&.is-hover-button, &:hover': {
            '&::before': {
              transform: 'scale(1) !important',
            },
            '&::after': {
              borderColor: '$white',
            },
            '& i': {
              color: '$white',
            },
          },
        },
      },
    },
  ],
  defaultVariants: {
    size: '2',
  },
});

type IProps = React.ComponentProps<typeof StyledIconButton> & {
  children?: React.ReactNode;
  className?: string;
  tracking?: ITracking;
  test?: ITest;
  onClick?: () => void;
  hoverchild?: boolean;
};

export const IconButton = (props: IProps) => {
  const options = {
    size: props.size,
    variant: props.variant,
    border: props.border,
    shadow: props.shadow,
    nobg: props.nobg,
    hover: props.hover,
    nothover: props.nothover,
    scrollTopStyle: props.scrollTopStyle,
  };
  return (
    <StyledIconButton
      {...options}
      className={`${props.className} ${
        props.hoverchild ? 'is-hover-button' : null
      }`}
      css={props.css}
      onClick={props.onClick && props.onClick}
      data-track={props.tracking && props.tracking.dataTrack}
      data-track-value={props.tracking && props.tracking.dataTrackValue}
      data-track-text={props.tracking && props.tracking.dataTrackText}
      data-track-url={props.tracking && props.tracking.dataTrackUrl}
      data-test={props.test && props.test.dataTest}
      aria-label="button"
    >
      {props.children}
    </StyledIconButton>
  );
};
IconButton.displayName = 'SharedElementIconButton';
