import type * as Stitches from '@stitches/react';
import { createStitches } from '@stitches/react';

export type { VariantProps } from '@stitches/react';

export const {
  config,
  createTheme,
  css,
  globalCss,
  getCssText,
  styled,
  keyframes,
  theme,
} = createStitches({
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      'primary-300': '#FEF9F9',
      'primary-200': '#FFF2F4',
      'primary-100': '#F5A6A3',
      primary: '#ED1C24',
      primary200: '#FB4B53',
      primary100: '#94141E',
      black: '#000000',
      gray1100: '#1A1A1A',
      gray1000: '#2E2E2E',
      gray900: '#444444',
      gray800: '#5D5D5D',
      gray700: '#777777',
      gray600: '#909090',
      gray500: '#AAAAAA',
      gray400: '#C3C3C3',
      gray300: '#EBEBEB',
      gray200: '#F6F6F6',
      white: '#FFFFFF',
      blue: '#0087E1',
      'blue-medium': '#284FB2',
      'blue-dark': '#1D3461',
      'green-lightest': '#00D16D',
      'green-light': '#00C380',
      green: '#009662',
      'gold-medium': '#C2AE80',
      bronze: '#9F3B1B',
      silver: '#5D5D5D',
      gold: '#987853',
      platinum: '#2E2E2E',
      'gradient-bronze': '40deg, #9F3B1B 0%, #C2826E 100%',
      'gradient-silver': '40deg, #8A8A8C 0%, #B4B4B5 100%',
      'gradient-gold': '40deg, #987853 0%, #BDA991 100%',
      'gradient-platinum': '40deg, #292929 0%, #767676 100%',
      gradientLight: '0deg, #F6F6F6 0%, #FFFFFF 100%',
      facebook: '#1877f2',
      line: '#00b900',
      backdrop70: 'rgba(0, 0, 0, 0.7)',
      lime: '#B3D334',
    },
    shadows: {
      'primary-300': '#FEF9F9',
      'primary-200': '#FFF2F4',
      'primary-100': '#F5A6A3',
      primary: '#ED1C24',
      primary200: '#FB4B53',
      primary100: '#94141E',
      'green-light': '#00C380',
      white: '#FFFFFF',
      black: '#000000',
      gray1000: '#2E2E2E',
      gray900: '#444444',
      gray800: '#5D5D5D',
      gray700: '#777777',
      gray600: '#909090',
      gray500: '#AAAAAA',
      gray400: '#C3C3C3',
      gray300: '#EBEBEB',
      gray200: '#F6F6F6',
      shadow100: '0px 8px 16px rgba(0, 0, 0, 0.04)',
      shadow200: '0px 16px 40px rgba(0, 0, 0, 0.08)',
      'shadow-none': 'none',
      'shadow-100': '0px 1px 4px rgba(0, 0, 0, 0.16)',
      'shadow-200': '0px 4px 8px rgba(0, 0, 0, 0.08)',
      'shadow-300': '0px 8px 16px rgba(0, 0, 0, 0.12)',
      'shadow-400': '0px 4px 32px rgba(0, 0, 0, 0.16)',
      'shadow-left':
        '-24px 24px 44px rgba(0, 0, 0, 0.08), -8px 8px 24px rgba(0, 0, 0, 0.24)',
      'shadow-right':
        '20px 20px 50px rgba(0, 0, 0, 0.36), 80px 80px 150px rgba(0, 0, 0, 0.24)',
    },
    radii: {
      'rounded-0': '0px',
      'rounded-0-5': '2px',
      'rounded-1': '4px',
      'rounded-1-5': '6px',
      'rounded-2': '8px',
      'rounded-3': '12px',
      'rounded-4': '16px',
      'rounded-6': '24px',
      'rounded-8': '32px',
      'rounded-full': '9999px',
    },
    space: {
      1: '4px',
      2: '8px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '32px', // $space-8
      8: '36px', // $space-9
      9: '40px', // $space-10
      10: '44px', // $space-11
      11: '48px', // $space-12
      12: '56px', // $space-14
      13: '64px', // $space-16
      14: '80px', // $space-20
      15: '96px', // $space-24
      16: '112px', // $space-28
      'space-0': '0px',
      'space-px': '1px',
      'space-0-5': '2px',
      'space-1': '4px',
      'space-1-5': '6px',
      'space-2': '8px',
      'space-3': '12px',
      'space-4': '16px',
      'space-5': '20px',
      'space-6': '24px',
      'space-7': '28px',
      'space-8': '32px',
      'space-9': '36px',
      'space-10': '40px',
      'space-11': '44px',
      'space-12': '48px',
      'space-14': '56px',
      'space-16': '64px',
      'space-20': '80px',
      'space-24': '96px',
      'space-28': '112px',
      'space-32': '128px',
      'space-36': '144px',
      'space-40': '160px',
      'space-44': '176px',
      'space-48': '192px',
      'space-52': '208px',
      'space-56': '224px',
      'space-60': '240px',
      'space-64': '256px',
      'space-72': '288px',
      'space-80': '320px',
      'space-96': '384px',
      'space-auto': 'auto',
      'space-sticky-top': 'var(--sticky-top)', // only top property
    },
    sizes: {
      1: '4px',
      2: '8px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '32px', // $size-8
      8: '36px', // $size-9
      9: '40px', // $size-10
      10: '44px', // $size-11
      11: '48px', // $size-12
      12: '56px', // $size-14
      13: '64px', // $size-16
      14: '80px', // $size-20
      15: '96px', // $size-24
      16: '112px', // $size-28
      'size-0': '0px',
      'size-px': '1px',
      'size-0-5': '2px',
      'size-1': '4px',
      'size-1-5': '6px',
      'size-2': '8px',
      'size-3': '12px',
      'size-4': '16px',
      'size-5': '20px',
      'size-6': '24px',
      'size-7': '28px',
      'size-8': '32px',
      'size-9': '36px',
      'size-10': '40px',
      'size-11': '44px',
      'size-12': '48px',
      'size-14': '56px',
      'size-16': '64px',
      'size-18': '72px',
      'size-20': '80px',
      'size-22': '88px',
      'size-24': '96px',
      'size-26': '104px',
      'size-28': '112px',
      'size-30': '120px',
      'size-32': '128px',
      'size-36': '144px',
      'size-40': '160px',
      'size-44': '176px',
      'size-48': '192px',
      'size-52': '208px',
      'size-56': '224px',
      'size-60': '240px',
      'size-64': '256px',
      'size-72': '288px',
      'size-80': '320px',
      'size-88': '352px',
      'size-96': '384px',
      'size-104': '416px',
      'size-112': '448px',
      'size-120': '480px',
      'size-124': '496px', // don't follow the consistent pattern of increments
      'size-128': '512px',
      'size-144': '576px',
      'size-auto': 'auto',
      'size-full': '100%',
      'size-min': 'min-content',
      'size-max': 'max-content',
      'size-fit': 'fit-content',
      'size-w-screen': '100vw', // only width property
      'size-h-screen': '100vh', // only height property
      'size-viewport-height': 'var(--viewport-height)', // only height property
      'size-sticky-viewport-height': 'var(--sticky-viewport-height)', // only height property
    },
    fontSizes: {
      0: '11px',
      1: '12px',
      2: '13px',
      3: '14px',
      4: '15px',
      5: '16px',
      6: '18px',
      7: '20px',
      8: '24px',
      9: '36px',
      10: '40px',
      11: '48px',
      12: '64px',
    },
    fonts: {
      regular: '"noto-sans-khmer", sans-serif',
      bold: '"noto-sans-khmer-bold", sans-serif',
      eurothai: 'eurothai-demi ,"noto-sans-khmer",sans-serif',
      eurothai_bold: 'eurothai-bold, "noto-sans-khmer-bold", sans-serif',
    },
    fontWeights: {},
    lineHeights: {
      '75': '75%',
      '100': '100%',
      '125': '125%',
      '150': '150%',
    },
    borderWidths: {
      0: '0px',
      1: '1px',
      2: '2px',
      4: '4px',
    },
    transitions: {},
    zIndices: {
      0: '0',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      header: '1000',
      floating: '2000',
      panel: '3000',
      overlay: '9000',
      modal: '10000',
      popup: '2147483645', // set max to hide the hotjar widget
      popupFloating: '2147483646', // set max to hide the hotjar widget
    },
  },
  media: {
    '2xs': '(min-width: 360px)',
    xs: '(min-width: 480px)',
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1280px)',
    '2xl': '(min-width: 1440px)',
    '3xl': '(min-width: 1680px)',
    '4xl': '(min-width: 1920px)',
    '5xl': '(min-width: 2560px)',
    maxxs: '(max-width: 479px)',
    maxsm: '(max-width: 639px)',
    maxmd: '(max-width: 767px)',
    maxlg: '(max-width: 1023px)',
    maxxl: '(max-width: 1279px)',
    max2xl: '(max-width: 1439px)',
    max3xl: '(max-width: 1679px)',
    motion: '(prefers-reduced-motion)',
    dark: '(prefers-color-scheme: dark)',
    light: '(prefers-color-scheme: light)',
    portrait: '(orientation: portrait)',
    landscape: '(orientation: landscape)',
    hover: '(hover: hover)',
    coarse: '(pointer: coarse)',
  },
  utils: {
    p: (value: Stitches.PropertyValue<'padding'>) => ({
      padding: value,
    }),
    pt: (value: Stitches.PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
    }),
    pb: (value: Stitches.PropertyValue<'paddingBottom'>) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
    }),
    pr: (value: Stitches.PropertyValue<'paddingRight'>) => ({
      paddingRight: value,
    }),
    px: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: Stitches.PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    m: (value: Stitches.PropertyValue<'margin'>) => ({
      margin: value,
    }),
    mt: (value: Stitches.PropertyValue<'marginTop'>) => ({
      marginTop: value,
    }),
    mb: (value: Stitches.PropertyValue<'marginBottom'>) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
    }),
    mr: (value: Stitches.PropertyValue<'marginRight'>) => ({
      marginRight: value,
    }),
    mx: (value: Stitches.PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: Stitches.PropertyValue<'marginTop'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    o: (value: Stitches.PropertyValue<'overflow'>) => ({ overflow: value }),
    ox: (value: Stitches.PropertyValue<'overflowX'>) => ({ overflowX: value }),
    oy: (value: Stitches.PropertyValue<'overflowY'>) => ({ overflowY: value }),
    pe: (value: Stitches.PropertyValue<'pointerEvents'>) => ({
      pointerEvents: value,
    }),
    us: (value: Stitches.PropertyValue<'userSelect'>) => ({
      WebkitUserSelect: value,
      userSelect: value,
    }),
    size: (value: Stitches.PropertyValue<'width'>) => ({
      width: value,
      height: value,
    }),
    w: (value: Stitches.PropertyValue<'width'>) => ({
      width: value,
    }),
    h: (value: Stitches.PropertyValue<'height'>) => ({
      height: value,
    }),
    ap: (value: Stitches.PropertyValue<'appearance'>) => ({
      WebkitAppearance: value,
      appearance: value,
    }),
    bs: (value: Stitches.PropertyValue<'boxShadow'>) => ({ boxShadow: value }),
    br: (value: Stitches.PropertyValue<'borderRadius'>) => ({
      borderRadius: value,
    }),
    bc: (value: Stitches.PropertyValue<'backgroundColor'>) => ({
      backgroundColor: value,
    }),
    linearGradient: (value: Stitches.PropertyValue<'backgroundImage'>) => ({
      backgroundImage: `linear-gradient(${value})`,
    }),
    radialGradient: (value: Stitches.PropertyValue<'backgroundImage'>) => ({
      backgroundImage: `radial-gradient(${value})`,
    }),
  },
});

export const globalStyles = globalCss({
  ':root': {
    '--transition-duration': '0.5s',
    '--transition-easing': 'cubic-bezier(0.25, 0.74, 0.22, 0.99)',
    '--transition-delay': '0s',
    '--transition-easing-slider': 'cubic-bezier(0.39, 0.01, 0.04, 1)',
    '--viewport-height': 'calc(var(--vh, 1vh)*100)',
    '--secondary-nav-height': '0px',
    '--primary-nav-height': '60px',
    '@lg': {
      '--secondary-nav-height': '0px',
      '--primary-nav-height': '54px',
    },
    '--header-height':
      'calc(var(--secondary-nav-height) + var(--primary-nav-height))',
    '--sticky-top': 0,
    '--sticky-viewport-height': 'calc(var(--vh, 1vh)*100)',
    '--product-nav-height': '60px',
    '--blur-small': '4px',
    '--blur-large': '8px',
  },
  '*': {
    margin: '$space-0',
    padding: '$space-0',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
  },
  '*, :after, :before': {
    boxSizing: 'border-box',
  },
  html: {
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    bc: '$white',
    blockSize: '-webkit-fill-available',
  },
  'html, body': {
    '-ms-text-size-adjust': 'none',
    '-moz-text-size-adjust': 'none',
    '-webkit-text-size-adjust': 'none',
    textSizeAdjust: 'none',
    height: 'fit-content',
  },
  body: {
    width: '100%',
    margin: '$space-0',
    minBlockSize: '-webkit-fill-available',
    fontFamily: '$regular',
    bc: '$white',
    '&.lock-scroll': {
      overflow: 'hidden',
      position: 'relative',
      padding: '0',
      paddingRight: 'var(--scrollbar)',
      margin: '0',
      pointerEvents: 'none',
      touchAction: 'none',
    },
    '&.lock-scroll-overflow': {
      overflow: 'hidden',
    },
  },
  '.is-line': {
    '.h-screen-w-header': {
      '@maxlg': {
        '@media (orientation: portrait)': {
          height: 'calc(var(--svh) - var(--header-height)) !important',
        },
      },
    },
    '.h-screen': {
      '@maxlg': {
        '@media (orientation: portrait)': {
          height: 'var(--svh) !important',
        },
      },
    },
    '.h-full-screen': {
      height: 'var(--svh) !important',
    },
  },
  main: {
    position: 'relative',
    width: '100%',
  },
  'article, aside, footer, header, nav, main, section, picture, figcaption, figure':
    {
      display: 'block',
    },
  'ul, ol': {
    listStyle: 'none',
    margin: '$space-0',
    padding: '$space-0',
  },
  a: {
    textDecoration: 'none',
    outline: 'none',
  },
  'blockquote, dd, dl, dt': {
    margin: '$space-0',
  },
  'img, embed, object, video': {
    maxWidth: '100%',
  },
  img: {
    display: 'block',
    us: 'none',
    border: 'none',
    '&[draggable=false]': {
      pe: 'none',
      '-webkit-user-drag': 'none',
    },
  },
  iframe: {
    display: 'block',
    border: 0,
  },
  '.swiper': {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    /* Fix of Webkit flickering */
    zIndex: 1,
    display: 'block',
    width: '100%',
  },
  '.swiper-pointer-events': {
    touchAction: 'pan-y',
  },
  '.swiper-wrapper': {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    transitionTimingFunction: 'var(--transition-easing-slider) !important',
    boxSizing: 'content-box',
  },
  '.swiper-android .swiper-slide, .swiper-ios .swiper-slide, .swiper-wrapper': {
    transform: 'translate3d(0px, 0, 0)',
  },
  '.swiper-slide': {
    flexShrink: '0',
    width: '100%',
    height: '100%',
    position: 'relative',
    transitionProperty: 'transform',
    display: 'block',
  },
  '.swiper-slide-invisible-blank': {
    visibility: 'hidden',
  },
  '.swiper-autoheight': {
    '&, .swiper-slide': {
      height: 'auto',
    },
    '.swiper-wrapper': {
      alignItems: 'flex-start',
      transitionProperty: 'transform, height',
    },
  },
  '.swiper-fade': {
    '.swiper-slide': {
      pe: 'none',
      transitionProperty: 'opacity',
    },
    '.swiper-slide-active': {
      pe: 'auto',
    },
  },
});

export type CSS = Stitches.CSS<typeof config>;

export const withDisplayName = (Original: any, displayName: string) => {
  return Object.assign({}, Original, {
    displayName: displayName,
  });
};

export const Main = styled('main', {
  variants: {
    theme: {
      light: {
        backgroundColor: '$white',
      },
      dark: {
        backgroundColor: '$black',
      },
    },
  },
  defaultVariants: {
    theme: 'light',
  },
});
