import type { MY24HeroBannerProps } from '@/components/sections/my24/home/hero-banner';
import { IMY24LineupCarousel } from '@/components/sections/my24/home/lineup-carousel';
import { getCta } from '@/contents/lcv/product/my24/cta';
import { HOME_LINEUP_DATA } from '@/contents/lcv/product/my24/models';

export const META_TAG_DATA = {
  title: 'ទំនាក់ទំនងមក​យើងខ្ញុំ​ - Isuzu TIS Cambodia',
  description: 'តាមដានព័ត៌មាន និងប្រូម៉ូសិនថ្មីៗរបស់ Isuzu នៅទីនេះ',
  img: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ebe5359f8c9db3/62fb311dea0d864c464abcaf/OG_ISUZU.jpg',
  },
};

export const HERO_BANNER_DATA: MY24HeroBannerProps = {
  headline: {},
  items: [
    {
      title:
        'ថ្មី! ISUZU D-MAX V-CROSS 4X4<span class="subtitle">UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt27a3939fded3b33b/6641995ae7f45d3d6d8b150b/banner-v-cross-mobile.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4719efcd8cf20689/6641995af6a9a375c02877a0/banner-v-cross.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4721b6e8faee609d/651528c9b16d6d5596554560/logo-new-isuzu-v-cross-4x4-white.png',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 246,
        height: 72,
      },
      buttons: [
        {
          label: 'មើលពត៌មានបន្ថែម',
          url: '/isuzu-v-cross',
          icon: 'arrow-forward',
          color: 'white',
          outline: true,
        },
      ],
    },
    {
      title:
        'ថ្មី! ISUZU D-MAX HI-LANDER <span class="subtitle">UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt86f08d327e96b2e5/6641995a907afeeb4aaf986c/banner-4door-mobile.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf35d6027b32e4a33/6641995aa2616e51abff1d17/banner-4door.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt90599ea4859b6c8c/64fd7594b169adb40804b401/logo-new-isuzu-hi-lander-white.svg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 217,
        height: 72,
      },
      buttons: [
        {
          label: 'មើលពត៌មានបន្ថែម',
          url: '/isuzu-pick-up-4-door',
          icon: 'arrow-forward',
          color: 'white',
          outline: true,
        },
      ],
    },
    {
      title:
        'ថ្មី! ISUZU D-MAX SPARK <span class="subtitle">UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2d74270442abf874/6641995a2ff97d862e704e96/banner-spark-mobile.jpg',
        alt: 'NEW! ISUZU D-MAX SPARK',
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltdc249682bf6f4e43/6641995a2a72d9184417b189/banner-spark.jpg',
        alt: 'NEW! ISUZU D-MAX SPARK',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd531cf0bb3b4ed81/64fd71a1bb60f75a2b4803cc/logo-new-isuzu-spark-white.svg',
        alt: 'NEW! ISUZU D-MAX SPARK',
        width: 217,
        height: 72,
      },
      buttons: [
        {
          label: 'មើលពត៌មានបន្ថែម',
          url: '/isuzu-spark',
          icon: 'arrow-forward',
          color: 'white',
          outline: true,
        },
      ],
    },
    {
      title:
        'ថ្មី! THE NEW MU-X <span class="subtitle">FURTHER THAN LUXURY</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9e18db0c682cd585/65d2bf29e07e36c541e1b26f/cambodia-mux-mobile.jpg',
        alt: 'The New MU-X be UNCHARTED',
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb2372d52fe8637c7/65d2bf29c520883c087f8937/cambodia-mux-desktop.jpg',
        alt: 'The New MU-X be UNCHARTED',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt763122c2e54c3734/64ca6ec33b94214081e9e37d/logo-the-new-mu-x-be-uncharted.png',
        alt: 'The New MU-X be UNCHARTED',
        width: 300,
        height: 70,
      },
      buttons: [
        {
          label: 'មើលពត៌មានបន្ថែម',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'white',
          outline: true,
        },
      ],
    },
  ],
};

export const LINEUP_CAROUSEL_DATA: IMY24LineupCarousel = {
  active: true,
  headline: {
    title: '',
  },
  items: HOME_LINEUP_DATA,
};

export const CTA_DATA = {
  ...getCta('home'),
};
