import type { BodyScrollOptions } from 'body-scroll-lock';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useSwitchTransition, useTransition } from 'transition-hook';

import Hamburger from '@/components/shared/element/hamburger';
import { IconButton } from '@/components/shared/element/icon-button';
import { getIcon, Icon } from '@/components/shared/element/icons';
import { Image } from '@/components/shared/element/image';
import { Link } from '@/components/shared/element/link';
import { Logo, LogoCommon } from '@/components/shared/element/logo';
import { LogoCommonLCV, LogoLCV } from '@/components/shared/element/logo/lcv';
import NewBadge from '@/components/shared/element/new-badge';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';
import { Button } from '@/components/shared/my24/button';
import { useWindowSize } from '@/components/shared/utility/hooks';
import { BUTTON_LCV_REGISTER } from '@/contents/lcv/link';
import { IWeb } from '@/types/config';
import { IMenu } from '@/types/layout/menu';

import {
  CarContentItem,
  CarContentList,
  CarMenuWrap,
  Close,
  Header,
  LogoWrap,
  MainNav,
  MenuItem,
  MenuList,
  MenuMobileClose,
  MenuMobileTop,
  MenuMobileTopInner,
  OtherMenuImage,
  OtherMenuImageItem,
  OtherMenuItem,
  OtherMenuList,
  OtherMenuWrap,
  Overlay,
  PrimaryNav,
  SubMenu,
  SubMenuBack,
  SubMenuBackIcon,
  SubMenuCar,
  SubMenuCarLCV,
  SubMenuInner,
} from './styled';

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
};

interface ICarMenuView extends IMenu {
  currentSubmenu?: number;
}
interface IProps {
  menuBuyingTool?: IMenu[];
  menuMain?: IMenu[];
  menuModel?: IMenu[];
  menuRegister?: IMenu;
  showMenuPanel?: boolean;
}

const CarMenuLayoutLCV = (props: ICarMenuView) => {
  return (
    <Grid gapY={{ '@lg': 4 }}>
      {props.label && (
        <Text size="h6" font="bold" variant="text-gray">
          {props.label}
        </Text>
      )}
      {props.submenu && props.submenu.length > 0 && (
        <SubMenuCarLCV>
          {props.submenu.map((sub, i) => (
            <React.Fragment key={i}>
              {sub.submenu && sub.submenu.length > 0 && (
                <SubMenuCar size="2">
                  {sub.submenu.map((j, i) => (
                    <Link
                      className="sub-menu-car-link"
                      href={j.url}
                      key={i}
                      tracking={{
                        dataTrack: 'primary-nav',
                        dataTrackText: j.label,
                        dataTrackUrl: j.url,
                      }}
                    >
                      <Box>
                        <Text
                          size={{
                            '@initial': 'base',
                            '@lg': 'h6',
                          }}
                          font="bold"
                          variant="text-black"
                        >
                          {j.label}
                        </Text>
                        <Text size="2xs" variant="text-gray-dark">
                          {j.description}
                        </Text>
                      </Box>
                      {j.image && (
                        <Box className="car-image">
                          {j.new && (
                            <NewBadge
                              css={{
                                top: -14,
                                '@lg': {
                                  top: -8,
                                },
                              }}
                            />
                          )}
                          <Image
                            fade={false}
                            blur={false}
                            ratio
                            size="4by3"
                            src={j.image.src}
                            alt={j.image.alt}
                            srcset={[
                              {
                                src: j.image.src,
                                breakpoint: 0,
                                width: 280,
                              },
                            ]}
                          />
                        </Box>
                      )}
                    </Link>
                  ))}
                </SubMenuCar>
              )}
            </React.Fragment>
          ))}
        </SubMenuCarLCV>
      )}
    </Grid>
  );
};

const View = (props: IProps) => {
  const WEB = process.env.NEXT_PUBLIC_WEB as IWeb;
  const windowSize = useWindowSize();
  const [submenu, setSubmenu] = useState(-1);

  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleBuyingTools, setToggleBuyingTools] = useState(false);
  const [mouseOver, setMouseOver] = useState(0);

  const [togglePanel, setTogglePanel] = useState(false);

  const menuToggleBuyingTools = useRef(null);
  const menuTogglePanel = useRef(null);
  const menuToggle = useRef(null);
  const subMenuToggle = useRef(null);
  const refArray = useRef([]);

  const router = useRouter();

  const transition = useSwitchTransition(submenu, 600, 'default');
  const OVERLAY_TRANS = useTransition(submenu !== -1, 1000);

  useEffect(() => {
    const handleRouteChange = () => {
      setSubmenu(-1);
      setToggleMenu(false);
      setToggleBuyingTools(false);
      setMouseOver(0);
      setTogglePanel(false);

      clearAllBodyScrollLocks();
      document.querySelector('header').classList.remove('is-product-menu-open');
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (windowSize.width >= 1024) {
      if (toggleBuyingTools) {
        setToggleBuyingTools(false);
      }
      if (toggleMenu) {
        setToggleMenu(false);
        setSubmenu(-1);
      }
    } else {
      if (!toggleMenu && submenu !== -1) {
        setSubmenu(-1);
      }
      if (togglePanel) {
        setTogglePanel(false);
      }
    }
  }, [toggleMenu, submenu, toggleBuyingTools, togglePanel, windowSize.width]);

  useEffect(() => {
    if (toggleMenu) {
      setToggleBuyingTools(false);
      document.documentElement.style.setProperty(
        '--menu-height',
        `${window.innerHeight}px`
      );
    } else {
      setSubmenu(-1);
    }
  }, [toggleMenu]);

  useEffect(() => {
    if (submenu !== -1) {
      setTogglePanel(false);
    }
  }, [submenu]);

  useEffect(() => {
    if (togglePanel) {
      setSubmenu(-1);
    }
  }, [togglePanel]);

  // NOTE: check lock scroll
  useEffect(() => {
    if (
      toggleBuyingTools || // mobile: buying tools
      toggleMenu || // mobile: menu
      (!toggleMenu && submenu !== -1) || // desktop: menu
      togglePanel // desktop: panel
    ) {
      // mobile: buying tools
      if (toggleBuyingTools) {
        if (menuToggleBuyingTools.current) {
          disableBodyScroll(menuToggleBuyingTools.current, options);
        }
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }

      // mobile: menu
      if (toggleMenu) {
        clearAllBodyScrollLocks();
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';

        if (submenu === -1) {
          if (menuToggle.current) {
            disableBodyScroll(menuToggle.current, options);
          }
          document.body.style.overflow = 'hidden';
          document.body.style.paddingRight = 'var(--scrollbar)';
        } else {
          if (refArray.current[submenu]) {
            disableBodyScroll(refArray.current[submenu], options);
          }
          document.body.style.overflow = 'hidden';
          document.body.style.paddingRight = 'var(--scrollbar)';
        }
        return;
      }

      // desktop: submenu
      if (!toggleMenu && submenu !== -1) {
        if (subMenuToggle.current) {
          disableBodyScroll(subMenuToggle.current, options);
        }
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }

      // desktop: panel
      if (togglePanel) {
        if (menuTogglePanel.current) {
          disableBodyScroll(menuTogglePanel.current, options);
        }
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }
    } else {
      clearAllBodyScrollLocks();
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
  }, [toggleBuyingTools, togglePanel, submenu, toggleMenu]);

  const onClickMenu = (index: number) => {
    if (submenu === index) {
      return setSubmenu(-1);
    }
    return setSubmenu(index);
  };

  return (
    <Header>
      <PrimaryNav className={submenu !== -1 && 'is-subnav-open'}>
        <Container>
          <Flex
            justify="between"
            align="center"
            css={{
              fontSize: '0',
              height: 'var(--primary-nav-height)',
            }}
          >
            <LogoWrap>
              <Link
                href="/"
                tracking={{
                  dataTrack: 'primary-nav',
                  dataTrackText: 'logo',
                  dataTrackUrl: '/',
                }}
              >
                {WEB === 'lcv' ? <LogoLCV /> : <Logo />}
              </Link>
            </LogoWrap>
            <Flex
              justify="between"
              align="center"
              gap={{ '@initial': '6', '@md': '7' }}
              css={{ height: '100%' }}
            >
              <MainNav className={toggleMenu && 'is-nav-open'}>
                {OVERLAY_TRANS.shouldMount && (
                  <Overlay
                    className={submenu !== -1 && 'is-subnav-open'}
                    onClick={() => setSubmenu(-1)}
                    css={{
                      '@lg': {
                        pe: OVERLAY_TRANS.stage === 'enter' ? 'all' : 'none',
                        opacity: OVERLAY_TRANS.stage === 'enter' ? '1' : '0',
                      },
                    }}
                  />
                )}
                <MenuList
                  ref={menuToggle}
                  variant="cv"
                  className={submenu !== -1 && 'is-subnav-open'}
                >
                  {props.menuMain.map((o, i) => (
                    <MenuItem
                      key={i}
                      variant="cv"
                      css={o.showPanel && { '@lg': { display: 'none' } }}
                    >
                      {o.url ? (
                        <Link
                          href={o.url}
                          target={o.target}
                          tracking={{
                            dataTrack: 'primary-nav',
                            dataTrackText: o.label,
                            dataTrackUrl: o.url,
                          }}
                        >
                          <Text
                            size={{
                              '@initial': 'h5',
                              '@lg': 'h6',
                            }}
                            variant="text-black"
                            css={{
                              pt: 2,
                              fontSize: 18,
                              '@lg': {
                                pt: 2,
                              },
                            }}
                            dangerouslySetInnerHTML={{ __html: o.label }}
                          />
                        </Link>
                      ) : (
                        <Box
                          key={i}
                          className={`menu-link ${
                            submenu === i && 'is-menu-open'
                          }`}
                          tracking={{
                            dataTrack: 'primary-nav',
                            dataTrackText: o.label,
                            dataTrackUrl: o.url,
                          }}
                          onClick={() => onClickMenu(i)}
                        >
                          <Text
                            size={{
                              '@initial': 'h5',
                              '@lg': 'h6',
                            }}
                            variant="text-black"
                            css={{
                              pt: 2,
                              fontSize: 18,
                              '@lg': {
                                pt: 2,
                              },
                            }}
                          >
                            {o.label}
                          </Text>
                          {o.submenu && o.submenu.length > 0 && (
                            <>
                              {!toggleMenu && (
                                <Icon
                                  variant="icon-black"
                                  css={{
                                    '@maxlg': {
                                      display: 'none',
                                    },
                                  }}
                                >
                                  {getIcon('expand-more')}
                                </Icon>
                              )}
                              {toggleMenu && (
                                <Icon
                                  variant="icon-black"
                                  css={{
                                    '@lg': {
                                      display: 'none',
                                    },
                                  }}
                                >
                                  {getIcon('chevron-right')}
                                </Icon>
                              )}
                            </>
                          )}
                        </Box>
                      )}
                      {o.submenu && o.submenu.length > 0 && (
                        <>
                          {transition((state, stage) => (
                            <>
                              {state === i && (
                                <SubMenu
                                  ref={subMenuToggle}
                                  className={submenu === i && 'is-subnav-open'}
                                  css={{
                                    '@maxlg': {
                                      pe: stage === 'enter' ? 'all' : 'none',
                                      transform:
                                        stage === 'enter'
                                          ? 'translateX(0)'
                                          : 'translateX(100%)',
                                      transitionProperty: 'transform',
                                      transitionDuration: '0.6s',
                                      transitionTimingFunction:
                                        'var(--transition-easing)',
                                      willChange: 'transform',
                                    },
                                    '@lg': {
                                      pe: stage === 'enter' ? 'all' : 'none',
                                      opacity: stage === 'enter' ? '1' : '0',
                                      transform:
                                        stage === 'enter'
                                          ? 'translateY(0)'
                                          : 'translateY(-40px)',
                                      transitionProperty: 'opacity, transform',
                                      transitionDuration: '0.6s',
                                      transitionTimingFunction:
                                        'var(--transition-easing)',
                                      willChange: 'opacity, transform',
                                    },
                                  }}
                                >
                                  {o.layout === 2 && (
                                    <Container size="5">
                                      <SubMenuBack
                                        onClick={() => setSubmenu(-1)}
                                      >
                                        <Flex gapX="1" align="center">
                                          <SubMenuBackIcon>
                                            <Icon variant="icon-red">
                                              {getIcon('chevron-left')}
                                            </Icon>
                                          </SubMenuBackIcon>
                                          <Text
                                            size="xl"
                                            variant="text-red"
                                            css={{
                                              pt: 2,
                                              lineHeight:
                                                'calc(var(--primary-nav-height) - 1px - 2px)',
                                            }}
                                          >
                                            {o.label}
                                          </Text>
                                        </Flex>
                                        <MenuMobileClose
                                          onClick={() =>
                                            setToggleMenu(!toggleMenu)
                                          }
                                        >
                                          <Icon variant="icon-black">
                                            {getIcon('close')}
                                          </Icon>
                                        </MenuMobileClose>
                                      </SubMenuBack>
                                      <CarMenuWrap
                                        ref={(ref) => {
                                          refArray.current[i] = ref;
                                        }}
                                      >
                                        <CarContentList size="2">
                                          {o.submenu.map((k, i) => (
                                            <CarContentItem size="2" key={i}>
                                              <CarMenuLayoutLCV {...k} />
                                            </CarContentItem>
                                          ))}
                                        </CarContentList>
                                        <Close onClick={() => setSubmenu(-1)}>
                                          <IconButton
                                            size="4"
                                            variant="icon-button-gray"
                                            nobg
                                          >
                                            <Icon>{getIcon('close')}</Icon>
                                          </IconButton>
                                        </Close>
                                      </CarMenuWrap>
                                    </Container>
                                  )}
                                  {o.layout !== 1 && o.layout !== 2 && (
                                    <SubMenuInner>
                                      <Container size="5">
                                        <SubMenuBack
                                          onClick={() => setSubmenu(-1)}
                                        >
                                          <Flex gapX="1" align="center">
                                            <SubMenuBackIcon>
                                              <Icon variant="icon-red">
                                                {getIcon('chevron-left')}
                                              </Icon>
                                            </SubMenuBackIcon>
                                            <Text
                                              size="xl"
                                              variant="text-red"
                                              css={{
                                                pt: 2,
                                                lineHeight:
                                                  'calc(var(--primary-nav-height) - 1px - 2px)',
                                              }}
                                            >
                                              {o.label}
                                            </Text>
                                          </Flex>
                                          <MenuMobileClose
                                            onClick={() =>
                                              setToggleMenu(!toggleMenu)
                                            }
                                          >
                                            <Icon variant="icon-black">
                                              {getIcon('close')}
                                            </Icon>
                                          </MenuMobileClose>
                                        </SubMenuBack>
                                        <OtherMenuWrap
                                          ref={(ref) => {
                                            refArray.current[i] = ref;
                                          }}
                                        >
                                          <Grid
                                            columns={{ '@lg': '12' }}
                                            gapX={{ '@lg': '2' }}
                                            css={{
                                              '@lg': {
                                                minHeight: 360,
                                              },
                                            }}
                                          >
                                            <Box
                                              css={{
                                                '@lg': {
                                                  position: 'relative',
                                                  gridColumn: '1 / span 5',
                                                },
                                                '@maxlg': {
                                                  display: 'none',
                                                },
                                              }}
                                            >
                                              <OtherMenuImage>
                                                {o.submenu.map((k, i) => {
                                                  return (
                                                    <OtherMenuImageItem
                                                      key={i}
                                                      className={
                                                        i === mouseOver &&
                                                        'is-current'
                                                      }
                                                    >
                                                      <Image
                                                        fullheight
                                                        blur
                                                        fade={false}
                                                        src={k.image.src}
                                                        alt={k.image.alt}
                                                        srcset={[
                                                          {
                                                            src: k.image.src,
                                                            breakpoint: 0,
                                                            width: 600,
                                                          },
                                                          {
                                                            src: k.image.src,
                                                            breakpoint: 1680,
                                                            width: 800,
                                                          },
                                                        ]}
                                                      />
                                                    </OtherMenuImageItem>
                                                  );
                                                })}
                                              </OtherMenuImage>
                                            </Box>
                                            <Box
                                              css={{
                                                '@lg': {
                                                  gridColumn: '8 / span 5',
                                                },
                                              }}
                                            >
                                              <OtherMenuList>
                                                {o.submenu.map((k, i) => (
                                                  <OtherMenuItem
                                                    key={i}
                                                    className={
                                                      i === mouseOver
                                                        ? 'is-current'
                                                        : null
                                                    }
                                                    onMouseOver={() =>
                                                      setMouseOver(i)
                                                    }
                                                  >
                                                    <Link
                                                      className="other-menu-link"
                                                      href={k.url}
                                                      target={k.target}
                                                      tracking={{
                                                        dataTrack:
                                                          'primary-nav',
                                                        dataTrackText: k.label,
                                                        dataTrackUrl: k.url,
                                                      }}
                                                    >
                                                      <Text
                                                        size={{
                                                          '@initial': 'h5',
                                                          '@lg': 'h6',
                                                        }}
                                                        variant="text-black"
                                                        css={{
                                                          pt: 2,
                                                          fontSize: 18,
                                                        }}
                                                      >
                                                        {k.label}
                                                      </Text>
                                                      {k.target && (
                                                        <Icon
                                                          variant="icon-black"
                                                          css={{
                                                            '@lg': {
                                                              display: 'none',
                                                            },
                                                          }}
                                                        >
                                                          {getIcon(
                                                            'open-in-new'
                                                          )}
                                                        </Icon>
                                                      )}
                                                    </Link>
                                                  </OtherMenuItem>
                                                ))}
                                              </OtherMenuList>
                                            </Box>
                                          </Grid>
                                          <Close onClick={() => setSubmenu(-1)}>
                                            <IconButton
                                              size="4"
                                              variant="icon-button-gray"
                                              nobg
                                            >
                                              <Icon>{getIcon('close')}</Icon>
                                            </IconButton>
                                          </Close>
                                        </OtherMenuWrap>
                                      </Container>
                                    </SubMenuInner>
                                  )}
                                </SubMenu>
                              )}
                            </>
                          ))}
                        </>
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
                {toggleMenu && (
                  <MenuMobileTop
                    variant="cv"
                    className={submenu !== -1 && 'is-subnav-open'}
                  >
                    <MenuMobileTopInner>
                      <LogoWrap>
                        <Link
                          href="/"
                          tracking={{
                            dataTrack: 'primary-nav',
                            dataTrackText: 'logo',
                            dataTrackUrl: '/',
                          }}
                        >
                          {WEB === 'lcv' ? <LogoCommonLCV /> : <LogoCommon />}
                        </Link>
                      </LogoWrap>
                      <MenuMobileClose
                        onClick={() => setToggleMenu(!toggleMenu)}
                      >
                        <Icon variant="icon-black">{getIcon('close')}</Icon>
                      </MenuMobileClose>
                    </MenuMobileTopInner>
                  </MenuMobileTop>
                )}
              </MainNav>
              <Box>
                <Button
                  label={BUTTON_LCV_REGISTER.label}
                  href={BUTTON_LCV_REGISTER.url}
                  variant={'primary'}
                  tracking={{
                    dataTrack: 'secondary-nav',
                    dataTrackText: BUTTON_LCV_REGISTER.label,
                    dataTrackUrl: '',
                  }}
                />
              </Box>
              <Box
                onClick={() => setToggleMenu(!toggleMenu)}
                css={{
                  '@lg': {
                    display: 'none',
                  },
                }}
                test={{
                  dataTest: 'hamburger',
                }}
              >
                <Hamburger />
              </Box>
            </Flex>
          </Flex>
        </Container>
      </PrimaryNav>
    </Header>
  );
};

View.displayName = 'Header';
export default View;
